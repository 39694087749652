<template>
  <b-card-code title="Add New">
    <validation-observer ref="VulnAddForm" #default="{ invalid }">
      <b-form class="auth-login-form mt-2" @submit.prevent="addVulnerability">
        <b-form-group label="Name" label-for="Name">
          <validation-provider
            #default="{ errors }"
            name="name"
            rules="required"
          >
            <b-form-input
              v-model="form.name"
              :state="errors.length > 0 ? false : null"
            ></b-form-input>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label="VM Name" label-for="vm_name">
          <validation-provider
            #default="{ errors }"
            name="vm_name"
            rules="required"
          >
            <b-form-input
              id="vm_name"
              v-model="form.vm_name"
              :state="errors.length > 0 ? false : null"
              name="vm_name"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label="" label-for="Is Publsihed?">
          <b-form-checkbox
            id="checkbox-2"
            v-model="form.is_reviewed"
            name="is_reviewed"
            value="true"
            unchecked-value="false"
          >
            Is Published?
          </b-form-checkbox>
        </b-form-group>

        <b-form-group label="CVSS Score" label-for="cvss_score">
          <validation-provider
            #default="{ errors }"
            name="cvss_score"
            rules="required"
          >
            <b-form-input
              id="cvss_score"
              v-model="form.cvss_score"
              :state="errors.length > 0 ? false : null"
              name="cvss_score"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label="OWASP Category" label-for="owasp_category">
            <v-select
              v-model="form.owasp_categories"
              multiple
              label="text"
              :options="owasp_options"
              @search="fetchOwaspOptions"
              autocomplete
            />
        </b-form-group>

        <b-form-group label="CVE" label-for="CVE">
          <b-form-textarea
            v-model="form.cve"
          ></b-form-textarea>
        </b-form-group>

        <b-form-group label="Description" label-for="Description">
          <validation-provider
            #default="{ errors }"
            name="Description"
            rules="required"
          >
            <b-form-textarea
              v-model="form.description"
              :state="errors.length > 0 ? false : null"
            ></b-form-textarea>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label="Severity" label-for="Severity">
          <validation-provider
            #default="{ errors }"
            name="severity"
            rules="required"
          >
            <b-form-select
              v-model="form.severity"
              :options="severity_options"
              :state="errors.length > 0 ? false : null"
            ></b-form-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label="Synopsis" label-for="synopsis">
          <b-form-textarea v-model="form.synopsis"></b-form-textarea>
        </b-form-group>

        <b-form-group label="Recommendations" label-for="recommendation">
          <b-form-textarea v-model="form.recommendation"></b-form-textarea>
        </b-form-group>

        <b-form-group label="References" label-for="references">
          <b-form-textarea
            id="references"
            v-model="form.references"
            name="references"
          />
        </b-form-group>

        <b-button type="submit" variant="primary" :disabled="invalid">
          Add
        </b-button>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import vSelect from "vue-select";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BTabs,
  BTab,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BFormTextarea,
  BInputGroupAppend,
  BInputGroup,
  BFormSelect,
  BFormCheckbox,
  BFormFile,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BCardCode,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BRow,
    BButton,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BInputGroupAppend,
    BInputGroup,
    BFormSelect,
    BFormCheckbox,
    BTabs,
    BTab,
    BFormFile,
    vSelect
  },
  data() {
    return {
      is_staff: this.$store.state.app.user.is_staff,
      severity_options: [
        { value: "critical", text: "Critical" },
        { value: "high", text: "High" },
        { value: "medium", text: "Medium" },
        { value: "low", text: "Low" },
        { value: "info", text: "Info" },
      ],
      owasp_options: [],
      form: {
        name: "",
        vm_name: "",
        cvss_score: "",
        owasp_categories:[],
        severity: null,
        description: "",
        synopsis: "",
        recommendation: "",
        references: "",
        cve: "",
        is_reviewed: false
      },
    };
  },
  created: function () {
    //  this.load(); --> not calling this due page load issues
  },
  methods: {
    load: function () {

    },

    addVulnerability: function () {
      this.$refs.VulnAddForm.validate().then((success) => {
        if (success) {
          //call to axios
          var self = this;
          const options = {
            method: "POST",
            headers: { "content-type": "application/json" },
            data: this.form,
            url: process.env.VUE_APP_BASEURL + "master/vulnerability/",
          };
          this.$http(options).then((res) => {
            console.log(res);
            if (res.data.id) {
              this.$router.push({
                name: "eca",
                // params: { id: this.form.asset_id },
              });
              // this.$router.push({name: 'Groups'});
              self.$toast({
                component: ToastificationContent,
                props: {
                  title: "Successfully added vulnerability! ",
                  icon: "EditIcon",
                  variant: "success",
                },
              });
            }
          });
        }
      });
    },
    fetchOwaspOptions(search, loading){
      if (search.length) {
        const a_options = {
          method: "GET",
          headers: { "content-type": "application/json" },
          url:
            process.env.VUE_APP_BASEURL +
            "master/owasp?query=true" +
            "&name=" +
            search,
        };
        var self = this;
        this.$http(a_options).then((res) => {
          self.owasp_options = [];
          res.data.results.map(function (value, key) {
            let a = {
              value: value.title,
              text: value.title,
              // full_details: value,
            };
            // console.log(res.data[key].profile.organization)
            self.owasp_options.push(a);

          });
        });
      }
    }
  },
};
</script>
<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
</style>