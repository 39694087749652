var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-code',{attrs:{"title":"Add New"}},[_c('validation-observer',{ref:"VulnAddForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.addVulnerability($event)}}},[_c('b-form-group',{attrs:{"label":"Name","label-for":"Name"}},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"VM Name","label-for":"vm_name"}},[_c('validation-provider',{attrs:{"name":"vm_name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"vm_name","state":errors.length > 0 ? false : null,"name":"vm_name"},model:{value:(_vm.form.vm_name),callback:function ($$v) {_vm.$set(_vm.form, "vm_name", $$v)},expression:"form.vm_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"","label-for":"Is Publsihed?"}},[_c('b-form-checkbox',{attrs:{"id":"checkbox-2","name":"is_reviewed","value":"true","unchecked-value":"false"},model:{value:(_vm.form.is_reviewed),callback:function ($$v) {_vm.$set(_vm.form, "is_reviewed", $$v)},expression:"form.is_reviewed"}},[_vm._v(" Is Published? ")])],1),_c('b-form-group',{attrs:{"label":"CVSS Score","label-for":"cvss_score"}},[_c('validation-provider',{attrs:{"name":"cvss_score","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"cvss_score","state":errors.length > 0 ? false : null,"name":"cvss_score"},model:{value:(_vm.form.cvss_score),callback:function ($$v) {_vm.$set(_vm.form, "cvss_score", $$v)},expression:"form.cvss_score"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"OWASP Category","label-for":"owasp_category"}},[_c('v-select',{attrs:{"multiple":"","label":"text","options":_vm.owasp_options,"autocomplete":""},on:{"search":_vm.fetchOwaspOptions},model:{value:(_vm.form.owasp_categories),callback:function ($$v) {_vm.$set(_vm.form, "owasp_categories", $$v)},expression:"form.owasp_categories"}})],1),_c('b-form-group',{attrs:{"label":"CVE","label-for":"CVE"}},[_c('b-form-textarea',{model:{value:(_vm.form.cve),callback:function ($$v) {_vm.$set(_vm.form, "cve", $$v)},expression:"form.cve"}})],1),_c('b-form-group',{attrs:{"label":"Description","label-for":"Description"}},[_c('validation-provider',{attrs:{"name":"Description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Severity","label-for":"Severity"}},[_c('validation-provider',{attrs:{"name":"severity","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.severity_options,"state":errors.length > 0 ? false : null},model:{value:(_vm.form.severity),callback:function ($$v) {_vm.$set(_vm.form, "severity", $$v)},expression:"form.severity"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Synopsis","label-for":"synopsis"}},[_c('b-form-textarea',{model:{value:(_vm.form.synopsis),callback:function ($$v) {_vm.$set(_vm.form, "synopsis", $$v)},expression:"form.synopsis"}})],1),_c('b-form-group',{attrs:{"label":"Recommendations","label-for":"recommendation"}},[_c('b-form-textarea',{model:{value:(_vm.form.recommendation),callback:function ($$v) {_vm.$set(_vm.form, "recommendation", $$v)},expression:"form.recommendation"}})],1),_c('b-form-group',{attrs:{"label":"References","label-for":"references"}},[_c('b-form-textarea',{attrs:{"id":"references","name":"references"},model:{value:(_vm.form.references),callback:function ($$v) {_vm.$set(_vm.form, "references", $$v)},expression:"form.references"}})],1),_c('b-button',{attrs:{"type":"submit","variant":"primary","disabled":invalid}},[_vm._v(" Add ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }